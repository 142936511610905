$brand-primary: #079F00;
$custom-input-bg: #a0a0a0;

$top-bg: #000;
$service-footer-bg: #414141;
$footer-bg: #000;
$filter-panel-color: #000;
$logo-img: "/images/gift4you/Gift4you_logo_white_cray_slogan.svg";
$background-img: "/images/gift4you/wallpaper-FI.jpg";

.hero {
    background: #ededed url("/images/gift4you/hero_photo-FI.JPG") center center no-repeat;
    background-size: cover;
    min-height: 500px;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.redeem-card-img {
    background-image: url("/images/gift4you/redeem_code_card-FI-2.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.top-logo {
    background-image: url($logo-img);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.footer-logo {
    background-image: url($logo-img);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;

    @media (min-width: 992px) {
        float: left;
        margin-left: 15px;
    }
}

.policy-listing {

    table.table > thead > tr > td:first-child {
        width: 70px;
    }

    table.table > thead {
        font-weight: bold;
    }

    table.table * {
        border: none;
    }

    table.table > thead > tr {
        border-bottom: 1px solid #cccccc;
    }

    table.table.cookie-data > thead > tr > td {
        width: 25%;
    }
}

.cc-btn {
    background-color: $brand-primary !important;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.main#custom-theme { /*using a custom theme ID makes it possible to get rid of important usage.*/
    .top .logo-wrapper {
        width: 25%;

        @media (min-width: $screen-md-min) {
            width: 95px;
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10 & IE11 CSS styles go here */
        .ie1011 {
            margin-left: 0px;
        }
    }
}