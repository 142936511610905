@import "morebox_partials/variables";


@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "gift4you_partials/_theme_settings.scss";

@import "morebox_partials/typography";
@import "morebox_partials/buttons";
@import "morebox_partials/forms";
@import "morebox_partials/generic_elements";
@import "morebox_partials/lightbox";


@import "morebox_partials/top";
@import "morebox_partials/hero";
@import "morebox_partials/filter_panel";
@import "morebox_partials/gift_cards";
@import "morebox_partials/product_view";
@import "morebox_partials/basket_page";
@import "morebox_partials/price_column";
@import "morebox_partials/checkout";
@import "morebox_partials/confirmation";
@import "morebox_partials/faq";
@import "morebox_partials/redeem";
@import "morebox_partials/how_to_footer";
@import "morebox_partials/footer";
@import "morebox_partials/_loading_andimation";

section.service-footer ul{
    margin-top: 0;
}

.Contact-form {
    padding-bottom: 40px;
}

.custom-content-container {
    position: relative;
    overflow: hidden;
    color: black;

    &.fill-default-content {
        margin: 0 -10px 0;

        @media (min-width: $screen-sm-min) {
            margin: 0 -40px 0;
        }
        /* padding: 75px 10px 50px 10px;
        
        @media (min-width: $screen-sm-min) {
            padding: 65px 40px 170px 40px;
        }

        */
    }

    .custom-content-background-image {
        position: absolute;

        &.center-vertically {
            bottom: 50%;
            top: 50%;
            margin-top: auto;
            margin-bottom: auto;
        }

        &.bottom-15 {
            bottom: 15%;
        }

        @media (min-width: $screen-sm-min) {
            &.center-vertically-sm {
                bottom: 50%;
                top: 50%;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }

    .content-container-text {
        position: relative;
        padding: 20px 15px;
        color: black;

        @media (min-width: $screen-sm-min) {
            padding: 20px 40px;
        }

        h1, h2 {
            text-align: center;
        }

        p {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 24px;
            color: black;
        }

        li {
            font-size: 20px;
            font-weight: 600;
            text-align: left;
            margin-bottom: 24px;
        }

        .headline-container {
            text-align: center;

            h1 {
                color: black;
                font-size: 24px;
                max-width: 760px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 50px;
                margin-top: 30px;
                line-height: 30px;
                padding-right: 20px;
                padding-left: 20px;
                display: inline-block;

                @media (min-width: $screen-sm-min) {
                    font-size: 38px;
                    line-height: 44px;
                    margin-top: 70px;
                    margin-bottom: 80px;
                }
            }

            h2 {
                max-width: 800px;
                margin: 0 auto;
                margin-bottom: 30px;
                display: inline-block;
                font-size: 30px;
                font-weight: 600;
                line-height: 32px;
            }
        }

        .text-light {
            color: white !important;
        }

        .text-dark {
            color: black !important;
        }
    }
}

.px40 {
    padding-left: 40px;
    padding-right: 40px;
}

.background-dark {
    background-color: #000;
}

.background-light {
    background-color: #fff;
}

.background-light80-dark20 {
    background: rgb(0,0,0);
    background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 20%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 20%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 100%);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 20%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
}

.w100 {
    width: 100%;
}

.mx-450 {
    max-width: 450px;
}

.mtn{
    margin-top: 0;
}

.mt40 {
    margin-top: 40px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.my30-sm {
    @media (min-width: $screen-sm-min) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.my120-sm {

    @media (min-width: $screen-sm-min) {
        margin-top: 120px;
        margin-bottom: 120px;
    }
}

.mb180-md {
    @media screen and (min-width: $screen-md-min) {
        margin-bottom: 180px !important; /*important hack for now*/
    }
}

.animation-rotate {
    animation: rotate 750ms infinite linear;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.confirmation.default-content {
    h1, h3 {
        color: black;
    }
}

footer{
    text-align: left;
}

@import "Default2/_AltaPay.scss";

.ListRounded {
    margin-bottom: 2rem !important;
    color: #fff !important;


    &:hover {
        color: #212529 !important;
        background-color: #f5f5f5;
    }
}

#altaPayErrorMessage {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: #fff;

}

.selected-altapayment .ListRounded {
    color: #212529 !important;
    background-color: #e9ecef;
}

#alta-payment-security-banner {
    max-width: 400px;
    margin: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .bi-lock-fill {
        font-size: 1.75rem;
        color: #a8a8a8;
    }

    p {
        font-size: 1rem;
        margin-bottom: 1rem;
        color: #a8a8a8;
    }

    .alta-payment-security-banner-methods-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        img {
            max-height: 30px;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 2rem;
        }
    }
}